<template>  
  <div>  
    <Row style="margin-bottom:20px;">  
      <Col span="12" >  
        <Input v-model="query" placeholder="请输入查询关键字" style="margin-left:50px"></Input>  
      </Col>  
      <Col span="12">  
        <Button type="primary" @click="search">查询</Button>  
      </Col>  
    </Row>  
    <Row>  
      <Col span="24">  
       <List  border>
        <ListItem border size="large"  style="display:flex;background-color:#57a3f3;font-weight:bold;color:white">
            <span style="flex:1">序号</span>
            <p style="flex:5;margin-left:20px;">姓名</p>
            <p style="flex:2; ">金额</p>
            </ListItem>
           <ListItem border size="large" v-for="(item ,i) in filteredData" :key="i" style="display:flex">
            <span style="flex:1">{{item.id}}</span>
            <p style="flex:5;margin-left:20px;">{{item.name}}</p>
            <p style="flex:2; ">{{item.amount}}元</p>
            </ListItem>
        </List>
      </Col>  
    </Row>  
  </div>  
</template>  
  
<script>  
export default {  
  data() {  
    return {  
      query: '',  
      data: [
    {
        "id": "1",
        "name": "李建军",
        "amount": "300"
    },
    {
        "id": "2",
        "name": "江龙华",
        "amount": "200"
    },
    {
        "id": "3",
        "name": "张传楚",
        "amount": "300"
    },
    {
        "id": "4",
        "name": "舒秋波",
        "amount": "300"
    },
    {
        "id": "5",
        "name": "李中明",
        "amount": "300"
    },
    {
        "id": "6",
        "name": "李中华",
        "amount": "500"
    },
    {
        "id": "7",
        "name": "刘安平",
        "amount": "500"
    },
    {
        "id": "8",
        "name": "刘安军",
        "amount": "300"
    },
    {
        "id": "9",
        "name": "李勇军",
        "amount": "200"
    },
    {
        "id": "10",
        "name": "李湘清",
        "amount": "200"
    },
    {
        "id": "11",
        "name": "李杰清",
        "amount": "200"
    },
    {
        "id": "12",
        "name": "廖方纯",
        "amount": "100"
    },
    {
        "id": "13",
        "name": "魏红权",
        "amount": "100"
    },
    {
        "id": "14",
        "name": "李金榜",
        "amount": "200"
    },
    {
        "id": "15",
        "name": "罗仁忠",
        "amount": "200"
    },
    {
        "id": "16",
        "name": "李师兵",
        "amount": "200"
    },
    {
        "id": "17",
        "name": "魏义科",
        "amount": "200"
    },
    {
        "id": "18",
        "name": "李红兵",
        "amount": "200"
    },
    {
        "id": "19",
        "name": "李杰明",
        "amount": "200"
    },
    {
        "id": "20",
        "name": "李成丹",
        "amount": "200"
    },
    {
        "id": "21",
        "name": "江龙军",
        "amount": "100"
    },
    {
        "id": "22",
        "name": "李安五",
        "amount": "200"
    },
    {
        "id": "23",
        "name": "李中友（叔）",
        "amount": "300"
    },
    {
        "id": "24",
        "name": "江用元",
        "amount": "300"
    },
    {
        "id": "25",
        "name": "李汉林",
        "amount": "200"
    },
    {
        "id": "26",
        "name": "黄丕龙",
        "amount": "300"
    },
    {
        "id": "27",
        "name": "陈登清",
        "amount": "300"
    },
    {
        "id": "28",
        "name": "朱洋华",
        "amount": "300"
    },
    {
        "id": "29",
        "name": "向华进",
        "amount": "300"
    },
    {
        "id": "30",
        "name": "彭勇昌",
        "amount": "300"
    },
    {
        "id": "31",
        "name": "李新民",
        "amount": "100"
    },
    {
        "id": "32",
        "name": "李桂英",
        "amount": "100"
    },
    {
        "id": "33",
        "name": "黄中棋",
        "amount": "100"
    },
    {
        "id": "34",
        "name": "李纪明",
        "amount": "100"
    },
    {
        "id": "35",
        "name": "李晓军（叔）",
        "amount": "200"
    },
    {
        "id": "36",
        "name": "陈钱",
        "amount": "200"
    },
    {
        "id": "37",
        "name": "李耀清",
        "amount": "200"
    },
    {
        "id": "38",
        "name": "陈文伯",
        "amount": "200"
    },
    {
        "id": "39",
        "name": "李校文",
        "amount": "200"
    },
    {
        "id": "40",
        "name": "吴华聪",
        "amount": "200"
    },
    {
        "id": "41",
        "name": "李湘",
        "amount": "200"
    },
    {
        "id": "42",
        "name": "李小兵（小四哥哥）",
        "amount": "200"
    },
    {
        "id": "43",
        "name": "李芹堂",
        "amount": "100"
    },
    {
        "id": "44",
        "name": "李亚明",
        "amount": "100"
    },
    {
        "id": "45",
        "name": "李秀华",
        "amount": "100"
    },
    {
        "id": "46",
        "name": "刘清国",
        "amount": "100"
    },
    {
        "id": "47",
        "name": "黄元中",
        "amount": "100"
    },
    {
        "id": "48",
        "name": "黄银中",
        "amount": "200"
    },
    {
        "id": "49",
        "name": "董明红",
        "amount": "200"
    },
    {
        "id": "50",
        "name": "李中喜",
        "amount": "200"
    },
    {
        "id": "51",
        "name": "李中余",
        "amount": "200"
    },
    {
        "id": "52",
        "name": "李国华",
        "amount": "200"
    },
    {
        "id": "53",
        "name": "李云华",
        "amount": "200"
    },
    {
        "id": "54",
        "name": "陈春华",
        "amount": "100"
    },
    {
        "id": "55",
        "name": "李树清",
        "amount": "200"
    },
    {
        "id": "56",
        "name": "李树桂",
        "amount": "200"
    },
    {
        "id": "57",
        "name": "李树胜",
        "amount": "200"
    },
    {
        "id": "58",
        "name": "廖方军",
        "amount": "100"
    },
    {
        "id": "59",
        "name": "廖方成",
        "amount": "200"
    },
    {
        "id": "60",
        "name": "蒋光辉",
        "amount": "500"
    },
    {
        "id": "61",
        "name": "覃仁富",
        "amount": "100"
    },
    {
        "id": "62",
        "name": "覃志刚",
        "amount": "200"
    },
    {
        "id": "63",
        "name": "陈双林",
        "amount": "200"
    },
    {
        "id": "64",
        "name": "钟军军",
        "amount": "200"
    },
    {
        "id": "65",
        "name": "廖战先",
        "amount": "200"
    },
    {
        "id": "66",
        "name": "李安章",
        "amount": "200"
    },
    {
        "id": "67",
        "name": "李耀中",
        "amount": "200"
    },
    {
        "id": "68",
        "name": "佘高文",
        "amount": "200"
    },
    {
        "id": "69",
        "name": "江克建",
        "amount": "500"
    },
    {
        "id": "70",
        "name": "江克军",
        "amount": "500"
    },
    {
        "id": "71",
        "name": "江龙志",
        "amount": "200"
    },
    {
        "id": "72",
        "name": "黄光辉",
        "amount": "200"
    },
    {
        "id": "73",
        "name": "黄光军",
        "amount": "200"
    },
    {
        "id": "74",
        "name": "李亚",
        "amount": "100"
    },
    {
        "id": "75",
        "name": "佘高兵",
        "amount": "100"
    },
    {
        "id": "76",
        "name": "陈孔辉",
        "amount": "100"
    },
    {
        "id": "77",
        "name": "李中明（九丫丫）",
        "amount": "100"
    },
    {
        "id": "78",
        "name": "李金权",
        "amount": "100"
    },
    {
        "id": "79",
        "name": "江龙飞",
        "amount": "500"
    },
    {
        "id": "80",
        "name": "魏仁新",
        "amount": "500"
    },
    {
        "id": "81",
        "name": "李秀强",
        "amount": "200"
    },
    {
        "id": "82",
        "name": "李小兵(丽丫丫)",
        "amount": "200"
    },
    {
        "id": "83",
        "name": "李小勇",
        "amount": "200"
    },
    {
        "id": "84",
        "name": "徐登中",
        "amount": "200"
    },
    {
        "id": "85",
        "name": "魏建新",
        "amount": "200"
    },
    {
        "id": "86",
        "name": "李新华",
        "amount": "200"
    },
    {
        "id": "87",
        "name": "李中清",
        "amount": "300"
    },
    {
        "id": "88",
        "name": "李中勇",
        "amount": "300"
    },
    {
        "id": "89",
        "name": "刘小平",
        "amount": "300"
    },
    {
        "id": "90",
        "name": "李艳",
        "amount": "300"
    },
    {
        "id": "91",
        "name": "李耀庆",
        "amount": "100"
    },
    {
        "id": "92",
        "name": "李耀喜",
        "amount": "200"
    },
    {
        "id": "93",
        "name": "杨永华",
        "amount": "200"
    },
    {
        "id": "94",
        "name": "杨军",
        "amount": "200"
    },
    {
        "id": "95",
        "name": "李伟（架桥）",
        "amount": "200"
    },
    {
        "id": "96",
        "name": "李银林",
        "amount": "100"
    },
    {
        "id": "97",
        "name": "钟发强",
        "amount": "200"
    },
    {
        "id": "98",
        "name": "黄丽珍",
        "amount": "200"
    },
    {
        "id": "99",
        "name": "谢丽",
        "amount": "200"
    },
    {
        "id": "100",
        "name": "李中友（李林）",
        "amount": "200"
    },
    {
        "id": "101",
        "name": "谢波",
        "amount": "200"
    },
    {
        "id": "102",
        "name": "李华林",
        "amount": "200"
    },
    {
        "id": "103",
        "name": "李中新",
        "amount": "200"
    },
    {
        "id": "104",
        "name": "李中芹",
        "amount": "500"
    },
    {
        "id": "105",
        "name": "李中平",
        "amount": "500"
    },
    {
        "id": "106",
        "name": "李崇林",
        "amount": "200"
    },
    {
        "id": "107",
        "name": "魏伟",
        "amount": "500"
    },
    {
        "id": "108",
        "name": "王国正",
        "amount": "500"
    },
    {
        "id": "109",
        "name": "钟盛华",
        "amount": "500"
    },
    {
        "id": "110",
        "name": "高强",
        "amount": "300"
    },
    {
        "id": "111",
        "name": "林元",
        "amount": "300"
    },
    {
        "id": "112",
        "name": "王军",
        "amount": "500"
    },
    {
        "id": "113",
        "name": "沙永红",
        "amount": "300"
    },
    {
        "id": "114",
        "name": "沙永中",
        "amount": "300"
    },
    {
        "id": "115",
        "name": "聂建军",
        "amount": "300"
    },
    {
        "id": "116",
        "name": "李智勇",
        "amount": "300"
    },
    {
        "id": "117",
        "name": "李安霞",
        "amount": "300"
    }
],  
      filteredData: [],  
    };  
  },  
  created (){
    this.search()
  },
  methods: {  
    search() {  
      if(this.query&&this.query!=''){
  this.filteredData = this.data.filter(item =>  
        item.name.includes(this.query) || item.age === Number(this.query) || item.gender === this.query,  
      );  
      }else{
        this.filteredData=this.data;
      }
    
    },  
  },  
};  
</script>